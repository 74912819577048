import React, { useContext, useRef, useEffect, useCallback } from "react";

import type { TeaserTypes } from "../../contexts/TeaserContext";
import type { Article } from "../../client";

import { ArticleContext } from "../../contexts/ArticleContext";
import { OrvestoContext } from "../../contexts/OrvestoContext";
import { FBPixelContext } from "../../contexts/FBPixelContext";
import { resourceToComponentMap } from "./Resources/resourceToComponents";
import { ArticleSide } from "./Side";
import { Ad } from "../Ad";
import { TrackingContext } from "../../contexts/TrackingContext";
import { SponsoredArticleDisclaimer } from "../SponsoredArticleDisclaimer";
import {
  shouldRenderBanner,
  brandStudioTrackingPixel,
  isMainResource,
  getFormatPostfix
} from "./helpers";
import { getDecorationProperties } from "../../client";
import { StickySponsorHeadingItem } from "../Feed/SponsorHeadingFeedItem";
import { GoogleTagTrackingContext } from "../../contexts/GoogleTagTrackingContext";
import { NativeArticleAd } from "../Ad/components/NativeArticleAd";
import { loadAdsWhenNavigate } from "../Ad/utils/ads/xandr";
import { useCookiePermission } from "../../hooks/cookiePermission/useCookiePermission";
import { getDynamicalPlacementInfo } from "../Ad/utils/ads/functions";
import { useGlobalStore } from "../../containers/GlobalStateContainer/store";
import { useConsentToAll } from "../../hooks/cookiePermission/useConsentToAll";

export function ExpandedArticle({
  setIsOpen,
  size
}: {
  setIsOpen: (article_id: string, activeArticleRef?: HTMLElement) => void;
  size: TeaserTypes;
}) {
  const { article } = useContext(ArticleContext);
  const { articleCollapse } = useContext(TrackingContext);
  const { trackPageView } = useContext(GoogleTagTrackingContext);
  const { trackOpenedArticle } = useContext(OrvestoContext);
  const { trackOpenedArticleTags } = useContext(FBPixelContext);
  const { adRules, salesposter } = useGlobalStore();
  const user = useGlobalStore();
  const openedArticleRef = useRef<HTMLDivElement | null>(null);

  const marketingConsent = useCookiePermission("CMP:marketing");
  const advertisingConsent = useCookiePermission("CMP:advertising");
  const consentToAll = useConsentToAll();

  const onExpand = useCallback(
    async (article: Article) => {
      if (marketingConsent !== null) {
        loadAdsWhenNavigate({
          user,
          ads: adRules,
          article,
          marketingConsent,
          advertisingConsent,
          consentToAll
        });
      }
    },
    [user, adRules, marketingConsent, advertisingConsent, consentToAll]
  );

  useEffect(() => {
    onExpand(article);
  }, [article, onExpand]);

  useEffect(() => {
    trackOpenedArticle(article);
    trackOpenedArticleTags(article);
    trackPageView({ article });
  }, [article, trackPageView, trackOpenedArticle, trackOpenedArticleTags]);

  const resources =
    article.resources.filter(
      resource => resource.type in resourceToComponentMap
    ) || [];

  const formatPostfix = getFormatPostfix(article.format);

  const dynamixAdInfo = getDynamicalPlacementInfo();

  return (
    <div className="article-forcefullwidth" ref={openedArticleRef}>
      {advertisingConsent && brandStudioTrackingPixel(article)}
      <div className="column main-anticolumn">
        <div
          className={`article-resource_column article-resource_column--${size} article-resource_column${formatPostfix} main-wide`}
        >
          <StickySponsorHeadingItem view="article" />
          {resources.map((resource, index, articleResources) => {
            if (
              size === "small" &&
              resource.type === "Image" &&
              isMainResource(article.main_resource, resource)
            ) {
              resource.format = size;
            }
            const ResourceComponent = resourceToComponentMap[resource.type];

            if (shouldRenderBanner(resource.type, index, articleResources)) {
              return (
                <React.Fragment key={index}>
                  <Ad
                    className="banner banner--mobilearticlepanorama hidden-sm hidden-md hidden-lg hidden-xl"
                    placement="mobileArticlePanorama"
                    id={`mobileArticlePanorama-${article.article_id}`}
                  />
                  <ResourceComponent
                    isOpen
                    isExpanded
                    article={article}
                    resource={resource}
                    group={resource.group}
                    decoration={
                      getDecorationProperties(
                        resource.decoration
                      ) as React.CSSProperties
                    }
                  />
                </React.Fragment>
              );
            } else {
              return (
                <ResourceComponent
                  isOpen
                  type={salesposter ? "inarticle-nodata" : "article"}
                  isExpanded
                  resource={resource}
                  article={article}
                  group={resource.group}
                  key={index}
                  decoration={
                    getDecorationProperties(
                      resource.decoration
                    ) as React.CSSProperties
                  }
                />
              );
            }
          })}
          <NativeArticleAd />
          <SponsoredArticleDisclaimer show={!!article?.sponsor} />
          <Ad
            id={`${dynamixAdInfo.idPrefix}-${article.article_id}`}
            className={`${dynamixAdInfo.classnames}`}
            placement={`${dynamixAdInfo.placement}`}
          />
        </div>
        <div className="main-line main-line--middle main-line--article" />
        <ArticleSide
          onClose={() => {
            setIsOpen(article.article_id);
            articleCollapse(article);
          }}
        />
      </div>
      <Ad
        id={`banner-articlepanorama-${article.article_id}`}
        className="hidden-xs banner banner--articlepanorama"
        placement="articlePanorama"
      />
    </div>
  );
}
