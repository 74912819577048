import React, { useContext } from "react";
import Head from "next/head";

import type { Article } from "../../client";

import { ArticleContext } from "../../contexts/ArticleContext";
import { resourceToComponentMap } from "./Resources/resourceToComponents";
import { ArticleSide } from "./Side";
import { Ad } from "../Ad";
import { getDecorationProperties } from "../../client/helpers";
import { SponsoredArticleDisclaimer } from "../SponsoredArticleDisclaimer";
import { StickySponsorHeadingItem } from "../Feed/SponsorHeadingFeedItem";
import {
  getFormatPostfix,
  brandStudioTrackingPixel,
  shouldRenderBanner
} from "./helpers";
import { omnify } from "../../lib/slugification";
import { getConfig } from "../../config";
import { NativeArticleAd } from "../Ad/components/NativeArticleAd";
import { useGlobalStore } from "../../containers/GlobalStateContainer/store";
import { getDynamicalPlacementInfo } from "../Ad/utils/ads/functions";
import { useCookiePermission } from "../../hooks/cookiePermission/useCookiePermission";

const getArticleCanonicalLink = (domain: string, article: Article) => {
  if (article.meta?.custom_properties?.canonical_url) {
    return article.meta?.custom_properties?.canonical_url;
  }

  return `https://${domain}/${omnify(article.title.value)}/a/${
    article.article_id
  }`;
};

type ArticleLargeProps = {
  isMainArticle: boolean;
};

export const ArticleLarge: React.FC<ArticleLargeProps> = ({
  isMainArticle
}) => {
  const { article } = useContext(ArticleContext);
  const { salesposter } = useGlobalStore();
  const { NEXT_PUBLIC_DOMAIN } = getConfig();
  const filteredResources = article.resources.filter(
    resource => resource.type in resourceToComponentMap
  );

  const advertisingConsent = useCookiePermission("CMP:advertising");

  const sponsoredClass = article.sponsor ? "article--sponsored" : "";
  const formatPostfix = getFormatPostfix(article.format);
  const dynamixAdInfo = getDynamicalPlacementInfo();

  return (
    <>
      <Head>
        <link
          rel="canonical"
          href={getArticleCanonicalLink(NEXT_PUBLIC_DOMAIN, article)}
        />
      </Head>
      <article
        id={`single-${article.article_id}`}
        className={`article article--full article-focused ${sponsoredClass} article--expanded`}
        style={getDecorationProperties(article.article_decoration)}
      >
        {advertisingConsent && brandStudioTrackingPixel(article)}
        <div className="article-forcefullwidth">
          <div className="column main-anticolumn">
            <div
              className={`article-resource_column article-resource_column${formatPostfix} article-resource_column--large main-wide`}
            >
              <StickySponsorHeadingItem view="article" />
              {filteredResources.map((resource, index, articleResources) => {
                const ResourceComponent = resourceToComponentMap[resource.type];

                return (
                  <React.Fragment key={index}>
                    {shouldRenderBanner(
                      resource.type,
                      index,
                      articleResources
                    ) && (
                      <Ad
                        className="banner banner--mobilearticlepanorama hidden-sm hidden-md hidden-lg hidden-xl"
                        placement="mobileArticlePanorama"
                        id={`mobileArticlePanorama-${article.article_id}`}
                      />
                    )}
                    <ResourceComponent
                      isOpen
                      resource={resource}
                      article={article}
                      group={resource.group}
                      decoration={getDecorationProperties(resource.decoration)}
                      type={salesposter ? "article" : "inarticle-nodata"}
                      isMainArticle={isMainArticle}
                    />
                  </React.Fragment>
                );
              })}
              <NativeArticleAd />
              <SponsoredArticleDisclaimer show={!!article?.sponsor} />
              <Ad
                id={`${dynamixAdInfo.idPrefix}-${article.article_id}`}
                className={`${dynamixAdInfo.classnames}`}
                placement={`${dynamixAdInfo.placement}`}
              />
            </div>
            <div className="main-line main-line--middle main-line--article" />
            <ArticleSide />
          </div>
          <Ad
            id={`banner-articlepanorama-${article.article_id}`}
            className="hidden-xs banner banner--articlepanorama"
            placement="articlePanorama"
          />
        </div>
      </article>
    </>
  );
};
