import React, { useContext, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import VisibilitySensor from "react-visibility-sensor";

import { getDecorationProperties } from "../../client";
import { PremiumLockContext } from "../../containers/PremiumLockContainer";
import { ActiveArticleContext } from "../../contexts/ActiveArticleContext";
import { ArticleContext } from "../../contexts/ArticleContext";
import { ArticlesFeedContext } from "../../contexts/ArticlesFeedContext";
import { TrackingContext } from "../../contexts/TrackingContext";
import { checkIfPassion } from "../../helpers";
import { ExpandedArticle } from "./ExpandedArticle";
import {
  clickedOnImage,
  clickedOnStar,
  clickedOnVideoThumbnail,
  generateSponsorHeader,
  GetArticleLinkType,
  getPosition,
  isBreaking,
  isCanvasAd,
  isExternalLink,
  isSupertagOrTopic,
  nonExpandableFormat
} from "./helpers";
import { getTeaserTypeForArticle, Teaser } from "./Teasers";
import { getDynamicBoxName, isDynamicBox } from "../../client/helpers";
import { TeaserGroupContext } from "../../contexts/TeaserGroupContext";

export function ArticleInFeed({
  isNotExpandable
}: {
  isNotExpandable?: boolean;
}) {
  const { article } = useContext(ArticleContext);
  const { checkLock } = useContext(PremiumLockContext);
  const { articles } = useContext(ArticlesFeedContext);
  const { group } = useContext(TeaserGroupContext);
  const { openActiveArticle, closeActiveArticle, article_ids } =
    useContext(ActiveArticleContext);
  const isOpen = !isNotExpandable && article_ids.includes(article.article_id);

  const { articleExpand, teaserClick, setSalesPosterReferrerArticle } =
    useContext(TrackingContext);

  const isSponsored = article?.sponsor;
  const isPassion = checkIfPassion(article.teaser_layout);

  const linkRef = useRef<HTMLAnchorElement | null>(null);
  const articleRef = useRef<HTMLAnchorElement | null>(null);

  const teaserType = getTeaserTypeForArticle(article);

  const classes = [
    "article",
    isOpen
      ? "article--expandable article--full"
      : "article--compact article--fadehr",
    isBreaking(article) ? "article--breaking" : false,
    isSponsored ? "article--sponsored" : false,
    isPassion ? "article--passion" : false
  ].filter(Boolean);
  const sponsorHeader = generateSponsorHeader(article);
  const teaser = <ArticleTeaser linkRef={linkRef} />;
  return (
    <CSSTransition in={isOpen} timeout={250} classNames="article">
      <article
        tabIndex={-1}
        ref={articleRef}
        style={getDecorationProperties(
          isOpen ? article.article_decoration : article.newsfeed_decoration
        )}
        onClick={e => {
          e.stopPropagation();
        }}
        onClickCapture={e => {
          if (checkLock()) {
            setSalesPosterReferrerArticle(article);
            teaserClick(
              article,
              getPosition(article, articles, isDynamicBox(group)),
              getDynamicBoxName(group)
            );

            return;
          }

          if (nonExpandableFormat(article) || isCanvasAd(article)) {
            teaserClick(
              article,
              getPosition(article, articles, isDynamicBox(group)),
              getDynamicBoxName(group)
            );
            return;
          }

          if (isNotExpandable) {
            e.stopPropagation();
            return;
          }

          if (
            isSupertagOrTopic(e.target as HTMLElement) ||
            isExternalLink(e.target as HTMLElement) ||
            (clickedOnImage(e) && isOpen)
          ) {
            return;
          }

          if (clickedOnVideoThumbnail(e, article) || clickedOnStar(e)) {
            e.preventDefault();

            return;
          }

          if (linkRef.current?.contains(e.target as Node)) {
            e.preventDefault();
            e.stopPropagation();
            teaserClick(
              article,
              getPosition(article, articles, isDynamicBox(group)),
              getDynamicBoxName(group)
            );
            articleExpand(
              article,
              getPosition(article, articles, isDynamicBox(group))
            );
          }

          if (articleRef?.current) {
            const targetElement = e.target as HTMLElement;
            const selection = window.getSelection()?.toString();

            if (
              selection &&
              selection.length > 0 &&
              articleRef.current.innerText.includes(selection)
            ) {
              return;
            }

            const closestButton = targetElement.closest("button");

            if (articleRef.current.contains(closestButton)) {
              return;
            }

            articleRef.current.focus();

            if (targetElement.dataset?.isclickable) {
              return;
            }

            if (isOpen) {
              closeActiveArticle(
                article.article_id,
                articleRef?.current as HTMLElement
              );
            } else {
              openActiveArticle(
                article.article_id,
                articleRef?.current as HTMLElement
              );
            }
          }
        }}
        onKeyPress={e => {
          if (e.keyCode === 13 && articleRef.current) {
            openActiveArticle(
              article.article_id,
              articleRef?.current as HTMLElement
            );
          }
        }}
        id={`feed-${article.article_id}`}
        className={classes.join(" ")}
        role="presentation"
      >
        <>
          {isOpen ? (
            <>
              {sponsorHeader}
              <ExpandedArticle
                setIsOpen={closeActiveArticle}
                size={teaserType}
              />
            </>
          ) : sponsorHeader ? (
            <div className="article--with-sponsor-header">
              {sponsorHeader}
              {teaser}
            </div>
          ) : (
            teaser
          )}
        </>
      </article>
    </CSSTransition>
  );
}

export function ArticleTeaser({
  linkRef
}: {
  linkRef?: React.Ref<HTMLAnchorElement>;
}) {
  const { article } = useContext(ArticleContext);
  const { group } = useContext(TeaserGroupContext);
  const { articles } = useContext(ArticlesFeedContext);
  const isSponsored = article?.sponsor;
  const { trackViewedAdInFeed } = useContext(TrackingContext);

  return (
    <VisibilitySensor
      scrollCheck={true}
      onChange={(isVisible: boolean) => {
        if (isVisible && isSponsored) {
          trackViewedAdInFeed(
            article,
            getPosition(article, articles, isDynamicBox(group))
          );
        }
      }}
    >
      <GetArticleLinkType article={article} linkRef={linkRef}>
        <Teaser article={article} />
      </GetArticleLinkType>
    </VisibilitySensor>
  );
}
